.light-theme {
  --foreground-rgb: 34, 34, 34; /* Darker text color */
  --background-start-rgb: 251, 251, 251; /* Background gradient start */
  --background-end-rgb: 241, 241, 241; /* Background gradient end */
  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #c21d03 0deg, /* Primary color 100 */
    #fd5732 55deg, /* Primary color 200 */
    #ffb787 120deg, /* Primary color 300 */
    #FF5733 160deg, /* Accent color 100 */
    transparent 360deg
  ); /* Primary glow */
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  ); /* Secondary glow */
  --tile-start-rgb: 251, 251, 251; /* Tile start color */
  --tile-end-rgb: 241, 241, 241; /* Tile end color */
  --tile-border: conic-gradient(
    #39393980,
    #bebebe40,
    #bebebe30,
    #bebebe20,
    #bebebe10,
    #bebebe10,
    #39393980
  ); /* Tile border gradient */
  --callout-rgb: 243, 245, 246; /* Callout background color */
  --callout-border-rgb: 211, 214, 216; /* Callout border color */
  --card-rgb: 200, 205, 208; /* Card background color */
  --card-border-rgb: 160, 163, 165; /* Card border color */
  --shadow-rgb: rgba(0, 0, 0, 0.2);
  /* Add more variables here */
  --text-h2: #232121; /* Text color 100 */
}
.dark-theme {
  --foreground-rgb: 235, 235, 235; /* Lighter text color */
  --background-start-rgb: 0, 0, 0; /* Background gradient start (negro) */
  --background-end-rgb: 36, 42, 52; /* Background gradient end (tono oscuro) */
  --primary-glow: radial-gradient(
    rgba(255, 0, 0, 0.4), /* Rojo */
    rgba(255, 0, 0, 0) /* Rojo transparente */
  ); /* Primary glow */
  --secondary-glow: linear-gradient(
    to bottom right,
    rgba(255, 0, 0, 0), /* Rojo transparente */
    rgba(255, 0, 0, 0), /* Rojo transparente */
    rgba(255, 0, 0, 0.3) /* Rojo semi-transparente */
  ); /* Secondary glow */
  --tile-start-rgb: 0, 0, 0; /* Tile start color (negro) */
  --tile-end-rgb: 36, 42, 52; /* Tile end color (tono oscuro) */
  --tile-border: conic-gradient(
    #ffffff80,
    #ffffff40,
    #ffffff30,
    #ffffff20,
    #ffffff10,
    #ffffff10,
    #ffffff80
  ); /* Tile border gradient */
  --callout-rgb: 26, 30, 38; /* Callout background color (tono oscuro) */
  --callout-border-rgb: 110, 110, 110; /* Callout border color (gris) */
  --card-rgb: 36, 42, 52; /* Card background color (tono oscuro) */
  --card-border-rgb: 88, 88, 88; /* Card border color (gris oscuro) */
  --shadow-rgb: rgba(180, 176, 176, 0.349);
  --text-h2: #FF4500; /* Naranja oscuro */
}
