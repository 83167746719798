@import url('https://fonts.googleapis.com/css2?family=Jacquard+12&display=swap');

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --neon-blue: #00f3ff;
  --neon-purple: #ff00ff;
  --dark-bg: #121212;
  --light-text: #e0e0e0;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar {
  width: 10px; 
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 166, 0, 0.671);  
  -webkit-backdrop-filter: blur(10px);  
          backdrop-filter: blur(10px);
  border: 3px solid white;
  border-radius: 10px;

}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffa600;
}




html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}


.light-theme {
  --foreground-rgb: 34, 34, 34; /* Darker text color */
  --background-start-rgb: 251, 251, 251; /* Background gradient start */
  --background-end-rgb: 241, 241, 241; /* Background gradient end */
  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #c21d03 0deg, /* Primary color 100 */
    #fd5732 55deg, /* Primary color 200 */
    #ffb787 120deg, /* Primary color 300 */
    #FF5733 160deg, /* Accent color 100 */
    transparent 360deg
  ); /* Primary glow */
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  ); /* Secondary glow */
  --tile-start-rgb: 251, 251, 251; /* Tile start color */
  --tile-end-rgb: 241, 241, 241; /* Tile end color */
  --tile-border: conic-gradient(
    #39393980,
    #bebebe40,
    #bebebe30,
    #bebebe20,
    #bebebe10,
    #bebebe10,
    #39393980
  ); /* Tile border gradient */
  --callout-rgb: 243, 245, 246; /* Callout background color */
  --callout-border-rgb: 211, 214, 216; /* Callout border color */
  --card-rgb: 200, 205, 208; /* Card background color */
  --card-border-rgb: 160, 163, 165; /* Card border color */
  --shadow-rgb: rgba(0, 0, 0, 0.2);
  /* Add more variables here */
  --text-h2: #232121; /* Text color 100 */
}
.dark-theme {
  --foreground-rgb: 235, 235, 235; /* Lighter text color */
  --background-start-rgb: 0, 0, 0; /* Background gradient start (negro) */
  --background-end-rgb: 36, 42, 52; /* Background gradient end (tono oscuro) */
  --primary-glow: radial-gradient(
    rgba(255, 0, 0, 0.4), /* Rojo */
    rgba(255, 0, 0, 0) /* Rojo transparente */
  ); /* Primary glow */
  --secondary-glow: linear-gradient(
    to bottom right,
    rgba(255, 0, 0, 0), /* Rojo transparente */
    rgba(255, 0, 0, 0), /* Rojo transparente */
    rgba(255, 0, 0, 0.3) /* Rojo semi-transparente */
  ); /* Secondary glow */
  --tile-start-rgb: 0, 0, 0; /* Tile start color (negro) */
  --tile-end-rgb: 36, 42, 52; /* Tile end color (tono oscuro) */
  --tile-border: conic-gradient(
    #ffffff80,
    #ffffff40,
    #ffffff30,
    #ffffff20,
    #ffffff10,
    #ffffff10,
    #ffffff80
  ); /* Tile border gradient */
  --callout-rgb: 26, 30, 38; /* Callout background color (tono oscuro) */
  --callout-border-rgb: 110, 110, 110; /* Callout border color (gris) */
  --card-rgb: 36, 42, 52; /* Card background color (tono oscuro) */
  --card-border-rgb: 88, 88, 88; /* Card border color (gris oscuro) */
  --shadow-rgb: rgba(180, 176, 176, 0.349);
  --text-h2: #FF4500; /* Naranja oscuro */
}

